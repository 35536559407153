<script setup>
  import imgUpload from '@/components/uploadImg'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const ruleFormRef = ref(null)
  const formData = reactive({
    headImg: '',
    nickname: '',
    mobile: '',
    gender: '',
    birthDate: ''
  })
  const data = reactive({
    loading: false
  })

  const rules = reactive({
    nickname: [
      { required: true, message: 'please input your name', trigger: 'blur' },
      { min: 3, max: 15, message: 'Length should be 3 to 15', trigger: 'blur' }
    ],
    birthDate: [{ required: true, message: 'Please select Birthday', trigger: 'blur' }]
  })

  watch(userStore.userInfo, () => {
    console.log('数据变更了')
  })

  //隐藏中间几位字符
  const structure = (array) => {
    let arrBox = [...array]
    var str = ''
    arrBox.map((res, index) => {
      str += index > 3 && index < 8 ? '*' : res
    })
    return str
  }

  const uploadImg = (list) => {
    formData.headImg = list && list.length ? list[0] : ''
  }

  const submitForm = async (formEl) => {
    console.log(formData, 'formData')
    await formEl.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        updateUser()
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  const updateUser = () => {
    data.loading = true
    api.user
      .updateUser(formData)
      .then((res) => {
        proxy.$toast(proxy.$t('Saved Successfully'), { type: 'succeed' })
        userStore.setUserInfo(res)
      })
      .catch((err) => {
        proxy.$toast(err.title)
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 禁用未来日期
  const disabledDate = (time) => {
    return time.getTime() > Date.now()
  }

  onMounted(() => {
    Object.assign(formData, userStore.userInfo)
  })
</script>

<template>
  <div>
    <div class="pro-main-breadcrumb rowSC">
      <span>{{ $t('Home') }}</span>
      <img src="~img/down_icon.svg" />
      <b>Personal Information</b>
    </div>
    <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="110px" class="form-box">
      <el-form-item label="Profile Picture" class="rowCC">
        <div class="upload-box">
          <imgUpload fileCount="1" :imgs="[formData.headImg]" @imgsCallback="uploadImg" />
        </div>
      </el-form-item>
      <el-form-item label="Name" prop="nickname">
        <el-input v-model="formData.nickname" placeholder="Please enter your name" clearable />
      </el-form-item>
      <el-form-item label="Phone" v-if="formData.mobile">
        <div class="Phone">{{ '+92' }}&nbsp;{{ structure(formData.mobile) }}</div>
      </el-form-item>
      <el-form-item label="Gender" prop="gender">
        <el-radio-group v-model="formData.gender" class="rowSC">
          <el-radio label="MALE" size="small">Male</el-radio>
          <el-radio label="FEMALE" size="small">Female</el-radio>
          <el-radio label="SECRECY" size="small">Secrecy</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Birthday" prop="birthDate">
        <el-date-picker v-model="formData.birthDate" type="date" placeholder="Pick a day" :disabledDate="disabledDate" />
      </el-form-item>
      <div class="btn-bo rowCC mt20">
        <div class="btn primary-btn" @click="submitForm(ruleFormRef)">Save Changes</div>
      </div>
    </el-form>
  </div>
</template>

<style lang="less" scoped>
  .pro-main-breadcrumb {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    line-height: 30px;

    img {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
  }

  .form-box {
    width: 360px;
    margin-top: 20px;

    .upload-box {
      width: 90px;
      height: 90px;
    }

    .Phone {
      width: 14px;
      color: #222;
      font-weight: 600;
    }

    .btn {
      padding: 12px 14px;
      background: var(--btnBgColor);
      color: #fff;
      border-radius: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  :deep .el-date-editor.el-input {
    width: 100%;
  }

  :deep .el-form-item__label {
    color: #222;
    font-size: 14px;
    font-weight: 500;
  }
</style>
