<script setup>
  import { ElMessage, ElNotification } from 'element-plus'
  import credentialsDialog from '@/views/order/compontent/credentials-dialog'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const credentialsDialogRef = ref()

  // 已收货 => isComment ? 跳转商品详情页|增加评论
  const handleReceipted = () => {
    if (order.isComment) {
      toEvaluate(order)
    } else {
      toProductDetail(order.productId)
    }
  }

  // 增加评论
  const toEvaluate = (order) => {
    // debugger;
    let orderSpecs = order.orderSpecs[0]
    let data = {}
    data.productCoverImg = order.productCoverImg
    data.productName = order.productName
    data.productId = order.productId
    data.productAmount = orderSpecs.amount
    data.orderNo = order.no
    data.orderId = order.id
    data.specName = orderSpecs.specName
    proxy.$router.push({
      name: 'ORDEREVALUATE',
      query: data
    })
  }

  // 已发货确定操作弹窗
  const confirmReceive = (order, callback) => {
    let title = proxy.$t('Confirm')
    let msg = proxy.$t('Do you confirm that you received the product?')
    proxy.$Message.confirm({
      title: title,
      msg: msg,
      okText: 'Confirm',
      onOk: () => {
        api.order
          .comfirmReceipt(order.mainOrderNo)
          .then((res) => {
            console.log('已发货确定操作弹窗', res)
            proxy.$toast(proxy.$t('Order cancelled successfully'), { type: 'succeed' })
            if (callback) {
              callback(res)
            }
          })
          .catch((error) => {})
      }
    })
  }

  // 取消订单弹窗
  const cancelOrder = (order, callback) => {
    let title = proxy.$t('Cancel order')
    let msg = proxy.$t('Do you really want to cancel this order?')
    proxy.$Message.confirm({
      title: title,
      msg: msg,
      onOk: () => {
        // ElMessage({
        //   message: 'Order cancelled successfully.',
        //   type: 'success'
        // })
        // ElNotification({
        //   title: 'Success',
        //   duration: '3000',
        //   message: 'Order cancelled successfully.',
        //   type: 'success'
        // })
        api.order
          .cancelOrderNo(order.mainOrderNo)
          .then((res) => {
            proxy.$toast(proxy.$t('Order cancelled successfully'), { type: 'succeed' })
            if (callback) {
              callback(res)
            }
          })
          .catch((error) => {})
      }
    })
  }

  // 跳转购买页
  const toPay = (order) => {
    console.log('去购买')
    router.push({ name: 'OTHERPAYMENT', query: { orderId: `${order.mainOrderNo}`, paymentType: `${order.channel}` } })
  }

  // 上传凭证处理
  const handleCredentials = (order) => {
    credentialsDialogRef.value.dialogVisibleShow(order)
  }

  // 跳转商品详情页
  const toProductDetail = (id) => {
    const { href } = router.resolve({
      name: 'productMain',
      params: {
        id: id
      }
    })
    window.open(href, '_blank')
  }

  defineExpose({
    confirmReceive,
    cancelOrder,
    toPay,
    handleCredentials
  })
</script>

<template>
  <div>
    <credentials-dialog ref="credentialsDialogRef" />
  </div>
</template>
