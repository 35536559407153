<script setup>
  import orderOs from '@/views/order/compontent/order-os'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const router = useRouter()
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['updataStatus'])
  const props = defineProps({
    list: {
      type: Array
    },
    noListTitle: {
      type: String
    }
  })

  const orderOsRef = ref()

  const data = reactive({
    curIndex: 0,
    orderInfo: {} // 操作上传凭证数据
  })

  // 跳转订单详情页
  const toOrderDetail = (order) => {
    proxy.$router.push({
      name: 'ORDERMAIN',
      params: { orderId: order.mainOrderNo }
    })
    // 退款流程
    // proxy.$router.push({
    //   name: 'REFUNDMAIN',
    //   params: {
    //     refundId: order.id,
    //     type: 'orderId'
    //   }
    // })
  }

  const goHome = () => {
    proxy.$router.push({
      name: 'home'
    })
  }

  // 跳转购买页
  const toPay = (order) => {
    orderOsRef.value.toPay(order)
  }

  // 上传凭证处理
  const handleCredentials = (order) => {
    orderOsRef.value.handleCredentials(order)
  }

  // 确认收货
  const confirmReceive = (order, index) => {
    data.curIndex = index
    orderOsRef.value.confirmReceive(order, updataStatus)
  }

  // 取消订单
  const cancelOrder = (order, index) => {
    console.log('取消订单', order)
    data.curIndex = index
    orderOsRef.value.cancelOrder(order, updataStatus)
  }

  // 已收货 => isComment ? 跳转商品详情页|增加评论
  const handleReceipted = (order) => {
    // if (order.isComment) {
    //   toEvaluate(order)
    // } else {
    //   toProductDetail(order.productId)
    // }
    proxy.$router.push({
      name: 'ORDEREVALUATE',
      query: {
        orderId: order.mainOrderNo
      }
    })
  }

  // 跳转评论页面
  const toEvaluate = (order) => {
    proxy.$router.push({
      name: 'ORDEREVALUATE',
      query: {
        orderId: order.mainOrderNo
      }
    })
  }

  let orderStatusJO = { CLOSED: -1, NOPAID: 0, PAYING: 1, PAYMENT_FAILED: 2, UNDISTRIBUTED: 3, DISTRIBUTED: 4, RECEIPTED: 5 }
  const disableDiv = (orderstatus, statusId) => {
    return orderStatusJO[orderstatus] < orderStatusJO[statusId]
  }
  // 判定取消订单是否展示
  const hanedCancelStataus = (statusData) => {
    const status = disableDiv(statusData, 'DISTRIBUTED') && statusData !== 'CLOSED'
    console.log(status, 'status')
    return status
  }

  const updataStatus = (status) => {
    $emit('updataStatus', {
      status,
      index: data.curIndex
    })
  }

  onMounted(() => {
    // search()
  })
</script>

<template>
  <div>
    <div class="order-list-box order_table" v-if="list.length > 0">
      <div class="order_header rowSC">
        <div class="item no1" data-remark="规格信息">{{ $t('Product Information') }}</div>
        <div class="item no2" data-remark="收件人姓名">{{ $t('Receiver') }}</div>
        <div class="item no3" data-remark="总金额">{{ $t('Amount') }}</div>
        <div class="item no2" data-remark="订单状态">{{ $t('Order Status') }}</div>
        <div class="item no2" data-remark="操作">{{ $t('Operation') }}</div>
      </div>

      <template v-for="(order, mainIndex) in list" :key="mainIndex">
        <div class="order-table-box">
          <div class="order-title rowBC">
            <div>
              <span>{{ JLTools.formatDateTime(order.mainOrderCreatedDate) }} </span>
              <span
                >{{ $t('Order Number') }}:<b>{{ order.mainOrderNo }}</b></span
              >
            </div>
          </div>
          <table class="order-table-center" cellspacing="0">
            <tr>
              <td class="no1 columnSS no1-box">
                <div v-for="(spec, specIndex) in order.subOrderSpecs" :key="specIndex" class="spec-item">
                  <div class="pro_main rowBC">
                    <div class="rowSC">
                      <div class="pro_img rowCC">
                        <JLImg v-if="spec.specCoverImg" :src="spec.specCoverImg" />
                      </div>
                      <div class="pro_info columnCS">
                        <div class="pro_name text_hide2 tl">
                          {{ spec.productName }}
                        </div>
                        <div class="pro_name text_hide2 tl">
                          {{ spec.specName }}
                        </div>
                      </div>
                    </div>
                    <div class="pro_num columnCC">
                      <span>Rs.{{ spec.amount }}</span>
                      <span>× {{ spec.purchaseNum }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="no2">
                <div class="user_name pl10 pr10">{{ order.subOrders[0].consignee }}</div>
              </td>
              <td class="no3">
                <!-- {{ order.orderStatus }} -->
                <div>{{ $t('Total') }}:Rs.{{ order.totalPaidAmount }}</div>
                <b class="mt5">{{ order.channel.replace('_', '') }}</b>
                <span v-if="false">{{ $t('Freight') }}:+Rs.0</span>
                <span v-if="false">{{ $t('Discount') }}:-Rs.100</span>
              </td>
              <td class="no4 status-td">
                <div class="columnCC">
                  <div v-if="order.orderStatus">
                    {{
                      order.orderStatus == 'UNDISTRIBUTED'
                        ? 'To Ship'
                        : order.orderStatus == 'DISTRIBUTED'
                        ? 'To Receive'
                        : order.orderStatus == 'RECEIPTED'
                        ? 'To Review'
                        : order.orderStatus === 'CLOSED'
                        ? 'Closed'
                        : order.orderStatus === 'PAYING'
                        ? 'Payment Pending'
                        : order.orderStatus === 'UNPAID'
                        ? 'Payment Pending'
                        : order.orderStatus === 'NOPAID'
                        ? 'Payment Pending'
                        : order.orderStatus === 'PAYMENT_FAILED'
                        ? 'Payment Pending'
                        : order.orderStatus
                    }}
                  </div>

                  <!-- 待支付状态  在线支付出现支付按钮, 转账出现上传凭证按钮 -->
                  <template v-if="order.orderStatus === 'NOPAID' || order.orderStatus == 'PAYING' || order.orderStatus == 'PAYMENT_FAILED'">
                    <button class="btn btn-primary mt5" v-if="order.channel === 'CARD' || order.channel === 'EASY_PAISA' || order.channel === 'JAZZ_CASH'" @click="toPay(order)">
                      {{ $t('Confirm') }}
                    </button>
                    <button class="btn2 mt5" v-else-if="order.channel === 'BANK_TRANSFER'" data-remark="0:待审核/1通过/-1审核不通过/2待提交" @click="handleCredentials(order)">
                      {{ order.bankTransferStatus === 0 ? $t('Awaiting Review') : order.bankTransferStatus === -1 ? $t('Rejected') : $t('Upload Credentials') }}
                    </button>
                  </template>
                </div>
              </td>
              <td class="no2 operation-td">
                <button class="btn btn-secondary" @click="toOrderDetail(order)">{{ $t('Order Details') }}</button>
                <button class="btn btn-secondary" data-remark="确认收货" v-if="order.orderStatus == 'DISTRIBUTED'" @click="confirmReceive(order, mainIndex)">Received</button>
                <template v-if="hanedCancelStataus(order.orderStatus)" data-remark="订单状态-待支付|待发货">
                  <button class="btn btn-secondary" data-remark="取消订单" @click="cancelOrder(order, mainIndex)">{{ $t('Cancel') }}</button>
                </template>
                <!--  -->
                <button
                  class="btn btn-secondary"
                  v-if="order.orderStatus === 'RECEIPTED'"
                  data-remark="已收货 => isComment ? 跳转商品详情页|增加评论"
                  @click="handleReceipted(order)"
                >
                  {{ $t('Review') }}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>
    <div class="no-order-box" v-else>
      <div class="columnCC">
        <p>{{ props.noListTitle }}</p>
        <div class="btn mt30 btn_color" @click="goHome">Continue Shopping</div>
      </div>
    </div>
  </div>
  <order-os ref="orderOsRef"></order-os>
</template>

<style lang="less" scoped>
  .order-list-box {
    width: 100%;
    padding: 15px;
    background: #fff;
  }

  .order-list-box {
    .order_header {
      width: 100%;
      height: 35px;
      background: #eee;

      .item {
        text-align: center;
        line-height: 35px;
        font-weight: bold;
        padding: 0 15px;
      }
    }
    .btn {
      min-width: 90px;
      padding: 6px;
      border-radius: 5px;
      border: 1px solid var(--btnBgColor);
      background: #fff;
      color: var(--btnBgColor);
      font-size: 10px;
      cursor: pointer;

      &:hover {
        background: var(--btnBgColor);
        color: #fff;
        transition: all 0.3s;
      }
    }

    .no1 {
      width: 370px;
    }

    .no2 {
      width: 120px;
    }

    .no3 {
      width: 150px;
    }

    .no4 {
      width: 135px;
      .btn {
        min-width: 70px;
      }
    }

    .btn2 {
      background-color: #fff;
      color: var(--btnBgColor);
      text-decoration: underline;
    }
  }

  .order-table-box {
    width: 100%;
    margin-top: 15px;
    border: 1px solid #e1e1e1;

    .order-title {
      width: 100%;
      padding: 0 20px;
      line-height: 35px;
      background: #f5f5f5;

      span {
        margin-right: 15px;
        color: #999;
        font-size: 13px;

        b {
          color: #000;
          margin-left: 2px;
        }
      }
    }
  }

  .order-table-center {
    line-height: 20px;

    tr {
      width: 100%;
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border-bottom: 0;
      }

      td {
        border-left: 1px solid #eee;
        text-align: center;

        &:first-child {
          border-left: 0px;
        }

        .btn-primary {
          background: var(--btnBgColor);
          color: white;
          border-radius: 50px;
          width: 80px;
          height: 28px;
        }

        .btn-secondary {
          border-radius: 50px;
        }
      }
    }

    .no1-box {
      overflow-x: auto;

      .spec-item {
        width: 100%;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }

        .spec-img {
          width: 100px;
          height: 100px;
          margin-right: 8px;
        }
      }
    }

    .pro_main {
      width: 100%;
      padding: 5px 15px;

      .pro_img {
        width: 70px;
        height: 70px;
        cursor: pointer;
      }

      .pro_info {
        width: fit-content;
        padding: 0 10px;

        .pro_name {
          line-height: 20px;
          cursor: pointer;

          &:hover {
            color: var(--btnBgColor);
          }
        }

        .pro_sx {
          margin-top: 4px;
          color: #999;
        }
      }

      .pro_num {
        line-height: 20px;
        color: #999;
      }
    }

    .user_name {
      width: 120px;
      word-wrap: break-word;
    }

    .status-td {
      a {
        cursor: pointer;
        text-decoration: underline;
        color: var(--btnBgColor);
      }
    }

    .operation-td {
      .btn {
        margin-top: 10px;
      }
    }
  }

  .no-order-box {
    width: 100%;
    min-height: 600px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #222;

    .btn {
      padding: 6px 14px;
      color: #757575;
      border-radius: 32px;
      border: 2px solid #757575;
      background: #fff;
      cursor: pointer;
    }

    .btn_color {
      background: var(--btnBgColor);
      border-color: var(--btnBgColor);
      color: #fff;
    }
  }
</style>
