<template>
  <div class="user_card_tab rowBC">
    <div class="rowSC">
      <div v-for="(item, index) in tabs" :key="index">
        <div class="item" :class="{ xuan: data.type === `${item.type}` }" @click="changeType(item.type)">
          <span>{{ $t(`${item.typeName}`) }}</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="search rowCC">
      <input class="search-input" v-model="data.search" @keyup.enter="search" />
      <div class="btn" @click="search" v-on:keyup.enter="search">{{ $t('Search') }}</div>
    </div>
  </div>

  <div class="content" v-loading="data.loading">
    <div v-if="!data.loading">
      <order-table :list="data.orderList" :noListTitle="data.noListTitle" @updataStatus="updataStatus" />
      <div class="w12fenye ml0">
        <div class="fenye_left">
          {{ $t('About') }} <b>{{ data.parms.total }}</b> {{ $t('results were found') }}
        </div>
        <div class="fenye_right">
          <Pagination :config="{ total: data.parms.total, pageSize: data.parms.size, currentPage: data.parms.page }" @currentPage="pageList"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import orderTable from './order-table'
  import Pagination from '@/components/Pagination'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import { mergeProperties } from '@/util/func'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const tabs = [
    { type: 'All Orders', typeName: 'All Orders', name_zh: '全部订单' },
    { type: 'NOPAID', typeName: 'To Pay', name_zh: '待付款' },
    { type: 'UNDISTRIBUTED', typeName: 'To Ship', name_zh: '待发货' },
    { type: 'DISTRIBUTED', typeName: 'To Receive', name_zh: '已发货' },
    { type: 'RECEIPTED', typeName: 'To Review', name_zh: '已收货' }
  ]

  const data = reactive({
    type: '',
    search: '', // 搜索
    loading: true,
    orderList: [],
    noListTitle: '',
    parms: {
      page: 1,
      size: 4,
      total: 0
    }
  })

  watch(
    () => userStore.userShowPage.selectType,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        data.type = userStore.userShowPage.selectType
        changeType()
      }
    }
  )

  const search = () => {
    data.parms.page = 1
    data.parms.total = 0
    data.orderList = []
    queryOrderList('search')
  }

  //查询订单列表
  const queryOrderList = (type) => {
    type = type || 'init'
    data.loading = true
    let params = {
      'userId.equals': userStore.userInfo.userId,
      sort: 'createdDate,desc',
      page: data.parms.page - 1,
      'search.contains': data.search,
      size: data.parms.size,
      'orderStatus.equals': data.type === 'All Orders' ? '' : data.type
    }
    api.order
      .orderList(params)
      .then((res) => {
        if (res && res.content && res.content.length) {
          // console.log(res, '订单列表')
          res.content.forEach((it) => {
            let subOrderSpecs = []
            subOrderSpecs = it.subOrders.map((item) => {
              item.subOrderSpecs.map((specs) => {
                specs.productName = item.productName
              })
              return item.subOrderSpecs
            })
            it.subOrderSpecs = [].concat(...subOrderSpecs)
          })
          data.orderList = res.content
          data.parms.total = res.totalElements
        } else {
          console.log('dddd')
          data.noListTitle = type === 'search' ? 'Order not found' : 'There are no orders placed yet.'
        }
        JLTools.toTop()
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 分页处理
  const pageList = (val) => {
    console.log(val)
    data.parms.page = val
    queryOrderList()
  }

  // 切换tab
  const changeType = (type) => {
    type = type || data.type
    data.parms.page = 1
    data.parms.total = 0
    data.orderList = []
    data.type = type
    userStore.setUserShowPage({ selectType: type })
    queryOrderList()
  }

  const updataStatus = (obj) => {
    console.log('更新状态', obj)
    if (data.orderList[obj.index].orderStatus) {
      data.orderList[obj.index].orderStatus = obj.status
    }
  }

  onMounted(() => {
    data.type = userStore.userShowPage.selectType || tabs[0].type
    changeType()
  })
</script>

<style lang="less" scoped>
  .user_body {
    float: right;
    width: 960px;
    min-height: calc(100vh - 150px);
    margin: 20px 0 20px 20px;
  }
  .user_card_tab {
    width: 100%;
    height: 61px;
    background: #fff;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    .item {
      line-height: 60px;
      position: relative;
      margin: 0 0 0 20px;
      font-size: 13px;
      .line {
        position: absolute;
        display: none;
        width: 50px;
        height: 3px;
        background: var(--btnBgColor);
        bottom: 10px;
        left: 50%;
        margin-left: -25px;
      }
    }
    .xuan {
      color: var(--btnBgColor);
      .line {
        display: block;
      }
    }
    .search {
      margin: 17.5px 15px;
      .search-input {
        width: 140px;
        height: 25px;
        border: 1px solid #ccc;
      }
      .btn {
        float: left;
        background: #f5f5f5;
        border: 1px solid #ccc;
        border-left: 0px;
        height: 25px;
        line-height: 23px;
        padding: 0 8px;
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 400px;
    height: auto;
    min-height: 600px;
  }

  .fenye {
    float: left;
    width: 100%;
    height: 60px;
    background: #fff;
    margin: 10px 0 20px 10px;
    border-radius: 4px;
  }
  .fenye_left {
    float: left;
    line-height: 60px;
    margin-left: 10px;
    font-size: 13px;
    color: #666;
  }
  .fenye_left b {
    font-size: 14px;
    color: #000;
  }
  .fenye_right {
    float: right;
    padding: 12px 0;
    margin-right: 5px;
  }
  .fenye_right button {
    float: left;
    height: 36px;
    min-width: 36px;
    line-height: 34px;
    padding: 0 10px;
    border: 1px solid #ccc;
    background: #fff;
    margin-right: 5px;
    font-size: 14px;
  }
  .fenye_right button:hover {
    cursor: pointer;
    background: #f5f5f5;
  }
  .fenye_right button.xuan {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
    color: #fff;
  }
  .fenye_right_previous img {
    float: left;
    width: 16px;
    height: 16px;
    margin: 8px 2px 8px 0;
    transform: rotate(90deg);
  }
  .fenye_right_previous span {
    float: left;
    margin-right: 3px;
  }
  .fenye_right_next img {
    float: left;
    width: 16px;
    height: 16px;
    margin: 8px 0 8px 2px;
    transform: rotate(270deg);
  }
  .fenye_right_next span {
    float: left;
    margin-left: 3px;
  }
  .fenye_right input {
    float: left;
    width: 50px;
    height: 36px;
    border: 1px solid #ccc;
    margin-right: 5px;
  }
  .fenye_right .txt {
    float: left;
    line-height: 36px;
    font-size: 14px;
    margin: 0 10px 0 5px;
  }
  .fenye_right_none {
    color: #ccc;
  }
  .fenye_right_none:hover {
    background: #fff !important;
    cursor: no-drop !important;
  }
</style>
